import * as styles from './listing.module.scss'

import PostsListingItem from './item'
import PropTypes from 'prop-types'
import React from "react"
import { graphql } from 'gatsby'

const PostsListing = ({ title, query, items }) => {
  return (
    <section className={styles.listing}>
      {( title !== '' &&
        <div className={styles.title}>{title}</div>
      )}
      {( items.length > 0 &&
        <div>
          {( items.map((post, index) => (
            <PostsListingItem key={index} post={post} title={post.title} publishDate={post.publishDate} visual={post.visual} teaser={post.teaser && post.teaser.teaser} category={post.category}/>
          )))}
        </div>
      )}
    </section>
  )
}

PostsListing.propTypes =  {
  title: PropTypes.string.isRequired,
  items: PropTypes.array
}

PostsListing.defaultProps = {
  title: '',
  items: []
}

export default PostsListing

export const query = graphql`
  fragment PostListing on ContentfulPostListing {
    title
    items {
      ...PostListingItem
    }
  }
`

// TODO: image, visual
