import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import * as styles from './index.module.scss';

const CallToAction = ({ ctaLink='', ctaLabel='', button=false, direction='right' }) => {
  if (ctaLabel === '' && ctaLink === '') {
    return null;
  }

  return (
    <div className={styles.cta} data-button={button}>
      <Link to={ctaLink} data-direction={direction}>
        <span>{ctaLabel}</span>
        <div className={styles.icon}>
          <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M340.485 366l99.03-99.029c4.686-4.686 4.686-12.284 0-16.971l-99.03-99.029c-7.56-7.56-20.485-2.206-20.485 8.485v71.03H12c-6.627 0-12 5.373-12 12v32c0 6.627 5.373 12 12 12h308v71.03c0 10.689 12.926 16.043 20.485 8.484z"></path></svg>
        </div>
      </Link>
    </div>
  )
};

CallToAction.propTypes = {
  ctaLink: PropTypes.string,
  ctaLabel: PropTypes.string,
  button: PropTypes.bool
}

export default CallToAction;
