import * as styles from './item.module.scss'

import { Link, graphql } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from "react"
import { pathTo } from '../../routes'
import CallToAction from '../calltoaction'
import FormatDate from '../date'

const PostsListingItem = ({ post, title, publishDate, teaser, visual, category }) => {
  return (
    <div className={styles.post}>
      {(visual &&
        <div className={styles.visual}>
          <Link to={pathTo(post)}>
            <GatsbyImage image={visual?.gatsbyImageData} alt={title} />
          </Link>
        </div>
      )}
      <div className={styles.text}>
        <Link to={pathTo(post)}>
          <div className={styles.date}>
            <FormatDate date={publishDate} />{category ? ` | ${category}` : null}
          </div>
          <div className={styles.title}>{title}</div>
        </Link>
        {(teaser &&
          <div className={styles.teaser}>{teaser}</div>
        )}
        <div className={styles.cta}>
          <CallToAction ctaLabel={'Weiterlesen'} ctaLink={pathTo(post)} />
        </div>
      </div>
    </div>
  )
}

PostsListingItem.propTypes = {
  title: PropTypes.string.isRequired,
  publishDate: PropTypes.string.isRequired,
  teaser: PropTypes.string,
  visual: PropTypes.object
}

PostsListingItem.defaultProps = {
  title: 'Please add a title to this post',
}

export default PostsListingItem

export const query = graphql`
  fragment PostListingItem on ContentfulPost {
    __typename
    title
    publishDate
    teaser {
      teaser
    }
    slug
    category
    visual: sharingImage {
      gatsbyImageData(
        aspectRatio: 1.4953271028
        cropFocus: CENTER
        resizingBehavior: THUMB
        placeholder: DOMINANT_COLOR
        quality: 75
        width: 320
      )
    }
  }
`
