import * as styles from './renderer.module.scss';

import DownloadsListing from '../downloads/listing'
import DownloadsListingItem from '../downloads/item'
import PhotosSidebar from '../photos/sidebar';
import PropTypes from 'prop-types';
import QuoteSidebar from '../quote/sidebar';
import React from 'react';
import YoutubePlayerSidebar from '../video/sidebar';
import { pathTo } from '../../routes'

function getValue(value) {
  return value !== null ? value : undefined;
}

const SidebarRenderer = ({ items }) => {
  const renderedItems = items.map((item, index) => {
    const key = item.id + index;

    switch (item.__typename) {
      case "ContentfulDownloadListing":
        return <DownloadsListing key={key} downloads={item.downloads || []}/>
      case 'ContentfulPhotos':
        return <PhotosSidebar key={key} photos={getValue(item.photos)} showCaption={getValue(item.showCaption)}/>;
      case 'ContentfulQuote':
        return <QuoteSidebar key={key} author={item?.author} text={getValue(item.text && item.text.text)}/>;
      case 'ContentfulYouTubePlayer':
        return <YoutubePlayerSidebar key={key} youTubeUrl={item.youTubeUrl || undefined} ctaLabel={item.ctaLabel || ''} ctaLink={item.ctaLink || ''} previewImage={item.previewImage || undefined}/>
      case 'ContentfulPost':
        return <DownloadsListingItem key={key} title={item.title} url={pathTo(item)} direction='right' target="_self"/>
      default:
        return null
    }
  });

  return (
    <div className={styles.renderer} data-item="sidebar-items">
      {renderedItems}
    </div>
  );
}


SidebarRenderer.propTypes = {
  items: PropTypes.array.isRequired
}

SidebarRenderer.defaultProps = {
  items: []
}

export default SidebarRenderer;
