import * as styles from './sidebar.module.scss'

import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'
import React from "react"
import { graphql } from 'gatsby'

const PhotosSidebar = ({ photos, showCaption }) => {
  const items = photos.map((photo, index) => (
    <div key={index} className={styles.photo}>
      <GatsbyImage image={photo.gatsbyImageData} alt={photo.title}/>
      {showCaption && <figcaption className={styles.figcaption}>{photo.title}</figcaption>}
    </div>
  ))
  return (
    <div>
      {items}
    </div>
  )
}

PhotosSidebar.propTypes = {
  photos: PropTypes.array.isRequired,
  showCaption: PropTypes.bool
}

PhotosSidebar.defaultProps = {
  photos: [],
  showCaption: false
}

export default PhotosSidebar

export const query = graphql`
  fragment Photos on ContentfulPhotos {
    id
    showCaption
    photos {
      id
      title
      gatsbyImageData(
        quality: 75
        placeholder: DOMINANT_COLOR
        width: 357
      )
    }
  }
`
