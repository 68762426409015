import * as styles from './index.module.scss'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Container from '../container'
import Divider from '../divider'
import SidebarRenderer from '../sidebar/renderer'
import TextRenderer from './renderer'

const Text = ({ alignment, text, sidebarItems, hasDivider, hasBackground, sidebarAlignment, sidebarSize }) => (
  <section className={styles.wrapper} data-has-background={hasBackground}>
    <Container>
      <div className={styles.row} data-has-sidebar={sidebarItems.length > 0}>
        <div className={styles.text} data-alignment={alignment.toLowerCase()} data-size={sidebarSize.toLowerCase()}>
          <TextRenderer text={text} />
        </div>
        {(sidebarItems.length > 0 &&
          <div className={styles.sidebar} data-alignment={sidebarAlignment.toLowerCase()} data-size={sidebarSize.toLowerCase()}>
            <SidebarRenderer items={sidebarItems} />
          </div>
        )}
      </div>
      {(hasDivider &&
        <Divider />
      )}
    </Container>
  </section>
)

Text.propTypes = {
  text: PropTypes.string.isRequired,
  alignment: PropTypes.string,
  sidebarItems: PropTypes.array,
  hasDivider: PropTypes.bool,
  sidebarAlignment: PropTypes.string,
  sidebarSize: PropTypes.string,
  hasBackground: PropTypes.bool
}

Text.defaultProps = {
  alignment: 'left',
  text: '',
  sidebarItems: [],
  sidebarAlignment: 'Center',
  hasDivider: false,
  sidebarSize: 'Default',
  hasBackground: false
}

export default Text

export const query = graphql`
  fragment Text on ContentfulText {
    id
    title
    alignment: textAlignment
    text {
      text
    }
    hasDivider
    hasBackground
    sidebarAlignment
    sidebarSize
    sidebarItems {
      __typename
      ... on ContentfulQuote {
        ...Quote
      }
      ... on ContentfulPhotos {
        ...Photos
      }
      ... on ContentfulDownloadListing {
        id
        title
        downloads: pressdownloads {
          __typename
          ... on ContentfulPressdownload {
            ...PressdownloadSidebar
          }
        }
      }
      ... on ContentfulYouTubePlayer {
        ...YouTubePlayer
      }
    }
  }
`
