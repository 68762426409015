// extracted by mini-css-extract-plugin
export var nominee = "artist-module--nominee--YnzHt";
export var fadein = "artist-module--fadein--e7B-0";
export var image = "artist-module--image--uE0DJ";
export var title = "artist-module--title--+rI+k";
export var modal = "artist-module--modal--5xiYb";
export var placeholder = "artist-module--placeholder--e4u4b";
export var info = "artist-module--info--LaBtr";
export var toggle = "artist-module--toggle--BZ1tC";
export var modalToggle = "artist-module--modalToggle--asZgY";
export var modalContent = "artist-module--modalContent--ei93Y";
export var modalClose = "artist-module--modalClose--Uj+MU";
export var close = "artist-module--close--svwfR";
export var stroke = "artist-module--stroke--TEb8h";
export var cross = "artist-module--cross--QF8HH";
export var modalName = "artist-module--modalName--71IC-";
export var modalMembers = "artist-module--modalMembers--lgFi0";
export var modalStatement = "artist-module--modalStatement--Sh+MI";
export var modalBiographie = "artist-module--modalBiographie--kR0BT";
export var backdrop = "artist-module--backdrop--PU6lY";