import * as styles from './renderer.module.scss';

import React, { Component } from 'react';

import AdmaMembers from '../members';
import Category from '../category';
import Gallery from '../gallery';
import Gateway from '../gateway';
import JudgesGrid from '../judges/grid';
import PageHeader from '../page/header';
import PostsListing from '../posts/listing';
import PressDownload from '../pressdownload';
import PropTypes from 'prop-types';
import Quote from '../quote';
import Text from '../text';
import VideoPlayer from '../video/player';
import WebsiteHeader from '../website/header';

function getValue(value) {
  return value !== null ? value : undefined;
}

class SectionsRenderer extends Component {
  render() {
    const { sections=[] } = this.props;

    if (!sections || sections.length === 0) { return null; }

    const renderedSections = sections.map((section, index) => {
      const key = section.id + index;

      switch (section.__typename) {
        case 'ContentfulAdmaMembers':
          return <AdmaMembers key={key} artistsPerPage={getValue(section.artistsPerPage)}/>
        case 'ContentfulCategory':
          return <Category key={key} title={getValue(section.title)} nominees={getValue(section.nominees)} winner={getValue(section.winner)}/>;
        case 'ContentfulJudgesGrid':
          return <JudgesGrid key={key} judges={getValue(section.judges)}/>;
        case 'ContentfulGateway':
          return <Gateway key={key} title={getValue(section.title)} items={getValue(section.items)}/>;
        case 'ContentfulGallery':
          return <Gallery key={key} title={getValue(section.title)} teaser={getValue(section.body && section.body.body)} preview={getValue(section.cover)} download={(section.download && section.download.file && section.download.file.url) || undefined} photos={getValue(section.photos)}/>;
        case 'ContentfulPageHeader':
          return <PageHeader key={key} {...section} headline={getValue(section.headline && section.headline.headline)} text={getValue(section.text && section.text.text)} hasDivider={getValue(section.hasDivider)} buttonLabel={getValue(section.buttonLabel)} buttonLink={getValue(section.buttonLink)} sidebarItems={getValue(section.sidebarItems)}/>
        case 'ContentfulPostListing':
          return <PostsListing key={key} title={getValue(section.title)} items={getValue(section.items)} query={getValue(section.query && section.query.query)}/>
        case 'ContentfulPressdownload':
          return <PressDownload key={key} title={section.title || ''} teaser={(section.teaser && section.teaser.teaser) || ''} download={(section.download && section.download.file && section.download.file.url) || undefined} preview={section.preview || undefined}/>
        case 'ContentfulQuote':
          return <Quote key={key} text={getValue(section.text && section.text.text)} author={getValue(section.author)} ctaLabel={getValue(section.ctaLabel)} ctaLink={getValue(section.ctaLink)}/>
        case 'ContentfulText':
          return <Text key={key} alignment={getValue(section.alignment)} text={getValue(section.text && section.text.text)} sidebarItems={getValue(section.sidebarItems)} hasDivider={getValue(section.hasDivider)} sidebarAlignment={getValue(section.sidebarAlignment)} sidebarSize={getValue(section.sidebarSize)} hasBackground={getValue(section.hasBackground)}/>
        case 'ContentfulYouTubePlayer':
          return <VideoPlayer key={key} title={getValue(section.title)} youTubeUrl={getValue(section.youTubeUrl)} previewImage={getValue(section.previewImage)} hasBackground={getValue(section.hasBackground)} ctaLabel={getValue(section.ctaLabel)} ctaLink={getValue(section.ctaLink)}/>
        case "ContentfulWebsiteHeader":
          return <WebsiteHeader key={key} headline={getValue(section.title)} copy={getValue(section.copy && section.copy.copy)} backgroundImage={getValue(section.backgroundImage)} backgroundVideo={getValue(section.backgroundVideo)} ctaLabel={getValue(section.ctaLabel)} ctaLink={getValue(section.ctaLink)} videoText={getValue(section.videoText)}/>
        default:
          return null;
      }
    });

    return (
      <div className={styles.renderer}>
        {renderedSections}
      </div>
    );
  }
}

SectionsRenderer.propTypes = {
  sections: PropTypes.array.isRequired
}

SectionsRenderer.defaultProps = {
  sections: []
}

export default SectionsRenderer;
