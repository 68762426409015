import * as styles from './sidebar.module.scss';

import PropTypes from 'prop-types';
import React from "react";
import TextRenderer from '../text/renderer';

const QuoteSidebar = ({ text, author }) => {
  return (
    <div data-item="sidebar-quote">
      <div className={styles.text}>
        <TextRenderer text={text}/>
      </div>
      { author && <div className={styles.author}>{author}</div> }
    </div>
  )
};

QuoteSidebar.propTypes = {
  text: PropTypes.string.isRequired
}

QuoteSidebar.defaultProps = {
  text: '',
  author: undefined
}

export default QuoteSidebar
