// extracted by mini-css-extract-plugin
export var wrapper = "header-module--wrapper--bhWLO";
export var hero = "header-module--hero--oDjf3";
export var videoText = "header-module--videoText--cV+np";
export var target = "header-module--target--sF8o4";
export var indicator = "header-module--indicator--FBoTY";
export var video = "header-module--video--IB8di";
export var text = "header-module--text--VgtgZ";
export var headline = "header-module--headline--dTnz9";
export var copy = "header-module--copy--gTuGe";
export var cta = "header-module--cta--k0evh";
export var fadein = "header-module--fadein--3kPPG";