import * as styles from './video.module.scss'

import React, { useState } from "react"

import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from 'prop-types'

const Video = ({ title, youTubeUrl = 'you?v=2', previewImage }) => {
  const [confirmed, setConfirmed] = useState(false)

  let videoId = youTubeUrl.split('?v=')[1]
  if (/youtu.be\/(\S*)/.test(youTubeUrl)) {
    videoId = youTubeUrl.split('.be/')[1]
  }

  console.log({ videoId })

  return (
    <div className={styles.video} data-confirmed={confirmed}>
      {(!confirmed && previewImage !== null &&
        <div className={styles.placeholder} onClick={() => setConfirmed(true)}>
          <div className={styles.play}>
            <img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMzFweCIgaGVpZ2h0PSIzOXB4IiB2aWV3Qm94PSIwIDAgMzEgMzkiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDYwLjEgKDg4MTMzKSAtIGh0dHBzOi8vc2tldGNoLmNvbSAtLT4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iSWNvbi1QbGF5LUJUTiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMzLjAwMDAwMCwgLTI1LjAwMDAwMCkiPgogICAgICAgICAgICA8ZyBpZD0iUGxheS1CVE4iPgogICAgICAgICAgICAgICAgPHBhdGggZD0iTTMzLjEyNjA1OTQsMjcuMDM2Mjc3OCBDMzMuMTI2MDU5NCwyNy4wMzYyNzc4IDMzLjE4NzY2NDMsMjUuNjgzNzIyMyAzNC4yNTE1NzE5LDI1LjM3MzkwMjggQzM1LjMxNTQ3OTUsMjUuMDY0MDgzNCAzNi4xMDc2NjIxLDI1LjM0MDg3NzggMzcuMTg4MDc0MiwyNS45OTgxNDA2IEM0MC4xMzQzNTUxLDI3Ljc5MDQ5NDUgNjIuMDY2NDQ4NCw0Mi44NDgyMDM2IDYyLjA2NjQ0ODQsNDIuODQ4MjAzNiBDNjIuMDY2NDQ4NCw0Mi44NDgyMDM2IDY0LjMxOTE4NDcsNDQuMjg1NDc1MSA2Mi4wNTIyNjk1LDQ1Ljg5MDI0MzMgQzU5Ljc4NTM1NDIsNDcuNDk1MDExNSAzNi4yMTg1Mjk3LDYyLjczNDI1NTIgMzYuMjE4NTI5Nyw2Mi43MzQyNTUyIEMzNi4yMTg1Mjk3LDYyLjczNDI1NTIgMzUuMjUyODk2Nyw2My40ODE2OTQ5IDM0LjI1MTU3MTksNjMuMTcxODc1NCBDMzMuMjUwMjQ3MSw2Mi44NjIwNTYgMzMuMDYzNDc2Niw2MS43NDIxMDY4IDMzLjA2MzQ3NjYsNjEuNzQyMTA2OCBMMzMuMTI2MDU5NCwyNy4wMzYyNzc4IFoiIGlkPSJQbGF5IiBmaWxsPSIjRkZGRkZGIj48L3BhdGg+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPgo=" alt="" title="" />
          </div>
          <GatsbyImage image={previewImage?.gatsbyImageData} alt={title} />
        </div>
      )}
      {(!confirmed && previewImage === null &&
        <div>Please add a preview image.</div>
      )}
      {(confirmed &&
        <iframe title={title} src={`https://www.youtube-nocookie.com/embed/${videoId}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" autoPlay allowFullScreen></iframe>
      )}
      <div className={styles.backdrop} />
    </div>
  )
}

Video.propTypes = {
  title: PropTypes.string,
  youTubeUrl: PropTypes.string,
}

Video.defaultProps = {
  title: ''
}

export default Video
