import * as styles from './index.module.scss';

import React, { useState } from "react";
import { StaticQuery, graphql } from 'gatsby';

import ArtistsListing from '../artists/listing';
import Container from '../container';
import Filter from './filter';
import Pagination from './pagination';
import PropTypes from 'prop-types';

const AdmaMembers = () => {
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(undefined);
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <section>
      <Container>
        <StaticQuery
          key={page}
          query={graphql`
            {
              artists: allContentfulArtist(sort: {order: ASC, fields: lastName}) {
                edges {
                  node {
                    id
                    lastName
                    firstName
                    members {
                      id
                      lastName
                      firstName
                    }
                    biographie {
                      biographie
                    }
                    visual {
                      title
                      description
                      gatsbyImageData(
                        aspectRatio: 1.4953271028
                        cropFocus: CENTER
                        resizingBehavior: THUMB
                        placeholder: DOMINANT_COLOR
                        quality: 75
                        width: 350
                      )
                    }
                  }
                }
              }
            }
          `}
          render={({ artists }) => {
            const artistsPerPage = 30;
            const skip = artistsPerPage * (page - 1);
            let filteredArtists = artists.edges.map(node => node.node);

            if (filter !== undefined) {
              filteredArtists = filteredArtists.filter(item => item.lastName.startsWith(filter));
            }

            if (searchQuery !== '') {
              filteredArtists = filteredArtists.filter(item => {
                return `${item.firstName} ${item.lastName}`.toLowerCase().search(searchQuery.toLowerCase()) > -1;
              });
            }

            const totalPages = Math.ceil(filteredArtists.length / artistsPerPage);
            const paginatedArtists = filteredArtists.slice(skip, skip+artistsPerPage);

            return (
              <div>
                <Filter filter={filter} setFilter={setFilter} query={searchQuery} setQuery={setSearchQuery}/>
                {( paginatedArtists.length === 0 &&
                  <div className={styles.empty}>Es wurden keine Mitglieder gefunden.</div>
                )}
                {( paginatedArtists.length > 0 &&
                  <>
                    <ArtistsListing artists={paginatedArtists}/>
                    <Pagination current={page} total={totalPages} setPage={setPage}/>
                  </>
                )}
              </div>
            )
          }}
        />
      </Container>
    </section>
  )
};

AdmaMembers.propTypes = {
  artistsPerPage: PropTypes.number.isRequired
}

AdmaMembers.defaultProps = {
  artistsPerPage: 5
}

export default AdmaMembers;
