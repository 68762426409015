import * as styles from './item.module.scss'

import PropTypes from 'prop-types'
import React from "react"

const DownloadsListingItem = ({ tag = "a", title, url, direction = 'down', target = "_blank" }) => {
  const arrow = (direction === 'down') ? (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
      <path d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z" fill="currentColor" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
      <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" fill="currentColor" />
    </svg>
  )

  return (
    <tag className={styles.download}>
      <a tohref={url} href={url} className={styles.anchor} target={target} rel="noopener noreferrer">
        <span>{title}</span>
        {arrow}
      </a>
    </tag>
  )
}

DownloadsListingItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
}

DownloadsListingItem.defaultProps = {
  title: 'Please add a title to this download',
  url: 'There is no url'
}

export default DownloadsListingItem
