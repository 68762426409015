import * as styles from './index.module.scss'

import Container from '../container'
import DownloadsListingItem from '../downloads/item'
import { GatsbyImage } from 'gatsby-plugin-image'
import PropTypes from "prop-types"
import React from "react"
import TextRenderer from '../text/renderer'
import { graphql } from 'gatsby'

const PressDownload = ({ title, teaser, preview, download }) => (
  <section>
    <Container>
      <div className={styles.row}>
        <div className={styles.image}>
          {( preview &&
            <GatsbyImage image={preview.gatsbyImageData}/>
          )}
        </div>
        <div className={styles.body}>
          {( title !== '' &&
            <div className={styles.title}>{title}</div>
          )}
          {( teaser !== '' &&
            <div className={styles.teaser}>
              <TextRenderer text={teaser}/>
            </div>
          )}
          {( download &&
            <div>
              <DownloadsListingItem title={`Download ${title}`} url={download}/>
            </div>
          )}
        </div>
      </div>
    </Container>
  </section>
)

PressDownload.propTypes = {
  title: PropTypes.string.isRequired,
  teaser: PropTypes.string.isRequired,
  preview: PropTypes.object.isRequired,
  download: PropTypes.string,
}

export default PressDownload

export const query = graphql`
  fragment Pressdownload on ContentfulPressdownload{
    id
    title
    teaser {
      teaser
    }
    download {
      file {
        url
      }
    }
    url
    preview {
      id
      gatsbyImageData(
        cropFocus: CENTER
        resizingBehavior: THUMB
        quality: 75
        placeholder: DOMINANT_COLOR
        width: 320
      )
    }
  }

  fragment PressdownloadSidebar on ContentfulPressdownload{
    id
    title
    download {
      file {
        url
      }
    }
    url
  }
`


// TODO: image, preview
