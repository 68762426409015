import * as styles from './header.module.scss'

import React, { useRef } from "react"

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import CallToAction from '../calltoaction'
import Container from '../container'
import Divider from '../divider'
import TextRenderer from '../text/renderer'

const WebsiteHeader = ({ headline, copy, backgroundImage, backgroundVideo, ctaLabel, ctaLink, videoText }) => {
  const text = useRef(null)

  return (
    <section className={styles.wrapper}>
      <div className={styles.hero} style={{ backgroundImage: `url(${backgroundImage?.file?.url}?w=1820)` }}>
        {(videoText !== '' &&
          <div className={styles.videoText}>{videoText}</div>
        )}
        <div className={styles.indicator} onClick={() => text.current.scrollIntoView({ behavior: "smooth" })}>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512">
            <path d="M169.4 502.6c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 402.7 224 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 370.7L86.6 329.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128z" fill="currentColor" />
          </svg>
        </div>
        {((backgroundVideo?.file?.url) &&
          <video className={styles.video} playsInline={true} loop src={backgroundVideo?.file?.url} autoPlay muted />
        )}
      </div>
      <div className={styles.target} ref={text} />
      <div className={styles.text}>
        <Container>
          <div className={styles.headline}>{headline}</div>
          <div className={styles.copy}>
            <TextRenderer text={copy} />
          </div>
          <div className={styles.cta}>
            <CallToAction ctaLabel={ctaLabel} ctaLink={ctaLink} />
          </div>
          <Divider />
        </Container>
      </div>
    </section>
  )
}

WebsiteHeader.propTypes = {
  headline: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  backgroundImage: PropTypes.object.isRequired,
  backgroundVideo: PropTypes.object,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string,
  videoText: PropTypes.string
}

WebsiteHeader.defaultProps = {
  headline: 'Please add a headline.',
  copy: 'Please add a copy text.',
  backgroundImage: {
    file: {
      url: 'https://images.unsplash.com/photo-1573041724425-6993819b9db2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9'
    }
  },
  ctaLabel: '',
  ctaLink: '',
  videoText: ''
}

export default WebsiteHeader

export const query = graphql`
  fragment WebsiteHeader on ContentfulWebsiteHeader {
    id
    title: headline
    copy {
      copy
    }
    ctaLabel
    ctaLink
    backgroundImage {
      id
    }
    backgroundVideo {
      id
      file {
        url
        fileName
      }
    }
    videoText
  }
`

// TODO: image, backgroundImage
