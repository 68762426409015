import DownloadsListingItem from './item';
import PropTypes from 'prop-types';
import React from "react";

const DownloadsListing = ({ downloads }) => {
  if (downloads === null) return null;

  return (
    <div>
      {downloads.map((download, index) => <DownloadsListingItem key={index} title={download.title} url={download.url || (download.download && download.download.file.url) || undefined}/>)}
    </div>
  )
};

DownloadsListing.propTypes =  {
  downloads: PropTypes.array
}

DownloadsListing.defaultProps = {
  downloads: []
}

export default DownloadsListing;
