import * as styles from './index.module.scss'

import { Link, graphql } from 'gatsby'

import CallToAction from '../calltoaction'
import Container from '../container'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from "react"
import { pathTo } from '../../routes'

const Gateway = ({ title, items }) => {
  const gates = items.map(item => (
    <div key={item.id} className={styles.item}>
      {( item.visual &&
        <div className={styles.visual}>
          {( item.slug &&
            <Link to={pathTo(item)}>
              <GatsbyImage image={item?.visual?.gatsbyImageData} alt={item.title}/>
            </Link>
          )}
          {( !item.slug &&
            <GatsbyImage image={item?.visual?.gatsbyImageData} alt={item.title}/>
          )}
        </div>
      )}
      <div className={styles.title}>
        {( item.slug &&
          <Link to={pathTo(item)}>
            {item.title}
          </Link>
        )}
        {( !item.slug && item.title )}
      </div>
      {( item.description && item.description.description &&
        <div className={styles.description}>
          {item.description.description}
        </div>
      )}
      {( item.slug &&
        <div className={styles.cta}>
          <CallToAction ctaLabel='Mehr Information' ctaLink={pathTo(item)}/>
        </div>
      )}
    </div>
  ))

  return (
    <section className={styles.gateway}>
      <Container>
        <div className={styles.headline}>{title}</div>
        <div className={styles.items}>
          {gates}
        </div>
      </Container>
    </section>
  )
}

Gateway.defaultProps = {
  title: 'Please remember to add a title',
  items: []
}

export default Gateway

export const query = graphql`
  fragment Gateway on ContentfulGateway {
    id
    title
    items {
      ... on ContentfulPage {
        id
        __typename
        title
        description: sharingDescription {
          description: sharingDescription
        },
        slug
        visual: sharingImage {
          gatsbyImageData(
            aspectRatio: 1.495
            cropFocus: CENTER
            resizingBehavior: THUMB
            placeholder: DOMINANT_COLOR
            quality: 75
            width: 545
          )
        }
      }
      ... on ContentfulPost {
        id
        __typename
        title
        description: sharingDescription {
          description: sharingDescription
        },
        slug
        visual: sharingImage {
          gatsbyImageData(
            aspectRatio: 1.495
            cropFocus: CENTER
            resizingBehavior: THUMB
            placeholder: DOMINANT_COLOR
            quality: 75
            width: 545
          )
        }
      }
      ... on ContentfulGatewayItem {
        id
        __typename
        title
        description: teaser {
          description: teaser
        },
        visual {
          gatsbyImageData(
            aspectRatio: 1.495
            cropFocus: CENTER
            resizingBehavior: THUMB
            placeholder: DOMINANT_COLOR
            quality: 75
            width: 545
          )
        }
      }
    }
  }
`
