import React from "react";
import PropTypes from 'prop-types';

import CategoryArtist from '../category/artist';

import * as styles from './listing.module.scss';

const ArtistsListing = ({ artists }) => {
  if (artists === null) return null;

  return (
    <div className={styles.artists}>
      {artists.map(artist => <CategoryArtist key={artist.id} lastName={artist.lastName} firstName={artist.firstName} biographie={(artist.biographie && artist.biographie.biographie) || undefined} visual={artist.visual} members={artist.members}/>)}
    </div>
  )
};

ArtistsListing.propTypes = {
  artists: PropTypes.array.isRequired
}

ArtistsListing.defaultProps = {
  artists: []
}

export default ArtistsListing;
