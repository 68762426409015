import React from "react";
import PropTypes from 'prop-types';

import CallToAction from '../calltoaction';
import Video from './video';
import * as styles from './player.module.scss';

const VideoPlayer = ({ title, youTubeUrl='you?v=2', previewImage, hasBackground, ctaLabel, ctaLink }) => (
  <section className={styles.wrapper} data-has-background={hasBackground}>
    <Video title={title} youTubeUrl={youTubeUrl} previewImage={previewImage}/>
    {( (ctaLabel !== '' && ctaLink !== '') &&
      <div className={styles.cta}>
        <CallToAction ctaLabel={ctaLabel} ctaLink={ctaLink}/>
      </div>
    )}
  </section>
);

VideoPlayer.propTypes = {
  title: PropTypes.string.isRequired,
  youTubeUrl: PropTypes.string,
  hasBackground: PropTypes.bool,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string
}

VideoPlayer.defaultProps = {
  hasBackground: false,
  title: 'Please add a title',
  ctaLabel: '',
  ctaLink: ''
}

export default VideoPlayer;
