import PropTypes from 'prop-types'
import React from "react"

import * as styles from './pagination.module.scss'

const Pagination = ({ current, total, setPage = () => { } }) => {
  if (total < 2) {
    return null
  }

  const pages = Array(total).fill().map((i, index) => {
    return (
      <div key={index} onClick={() => { setPage(index + 1); window.scrollTo({ top: 0, behavior: 'smooth' }) }} className={styles.page} data-active={index + 1 === current}>{index + 1}</div>
    )
  })

  return (
    <div className={styles.pagination}>
      {(current > 2 &&
        <div onClick={() => { setPage(current - 1); window.scrollTo({ top: 0, behavior: 'smooth' }) }} className={styles.prev}>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 288 480 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-370.7 0 73.4-73.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-128 128z" fill="currentColor" />
          </svg>
        </div>
      )}
      {pages}
      {(current < total &&
        <div onClick={() => { setPage(current + 1); window.scrollTo({ top: 0, behavior: 'smooth' }) }} className={styles.next}>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" fill="currentColor" />
          </svg>
        </div>
      )}
    </div>
  )
}

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default Pagination
