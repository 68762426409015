// extracted by mini-css-extract-plugin
export var header = "header-module--header--aQ2aa";
export var headlineWapperWithImage = "header-module--headlineWapperWithImage--MU5BC";
export var headline = "header-module--headline--FfcT8";
export var headlineWapper = "header-module--headlineWapper--zKmLC";
export var sectionsWrapperWithSections = "header-module--sectionsWrapperWithSections--oXqde";
export var text = "header-module--text--tL6IC";
export var items = "header-module--items--acyAG";
export var sectionsWrapper = "header-module--sectionsWrapper--QMBmc";
export var cta = "header-module--cta--cHfjz";
export var fadein = "header-module--fadein--DRC4H";