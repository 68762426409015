import React from "react";
import PropTypes from 'prop-types';

import * as styles from './index.module.scss';

const Toggle = ({ onClick, active }) => {
  return (
    <div className={styles.toggle} onClick={onClick} data-active={active}/>
  )
};

Toggle.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool
}

Toggle.defaultProps = {
  onClick: () => { console.error('Please add an onclick prop.') },
  active: false
}

export default Toggle;
