import * as styles from './header.module.scss'

import CallToAction from '../calltoaction'
import Container from '../container'
import Divider from '../divider'
import PropTypes from 'prop-types'
import React from "react"
import SidebarRenderer from '../sidebar/renderer'
import TextRenderer from '../text/renderer'
import { graphql } from 'gatsby'

const PageHeader = ({ headline, text, hasDivider, buttonLabel, buttonLink, sidebarItems, backgroundImage }) => {
  const style = { backgroundImage: `url(${backgroundImage ? backgroundImage.file.url : ''}?w=1920)` }
  const headlineWrapperClassName = backgroundImage ? styles.headlineWapperWithImage : styles.headlineWapper
  const sectionsWrapperClassName = sidebarItems.length > 0 ? styles.sectionsWrapperWithSections : styles.sectionsWrapper

  return (
    <section className={styles.header} data-has-background={!!backgroundImage}>
      { headline && (
        <div className={headlineWrapperClassName} style={style}>
          <Container>
            <div className={styles.headline}>
              <TextRenderer text={headline}/>
            </div>
          </Container>
        </div>
      )}
      <Container>
        <div className={sectionsWrapperClassName}>
          <div className={styles.text}>
            { text && <TextRenderer text={text}/>}
            <div className={styles.cta}>
              <CallToAction ctaLabel={buttonLabel} ctaLink={buttonLink} button={true}/>
            </div>
          </div>
          {( sidebarItems.length > 0 &&
            <div className={styles.items}>
              <SidebarRenderer items={sidebarItems}/>
            </div>
          )}
        </div>
        { hasDivider && <Divider/> }
      </Container>
    </section>
  )
}

PageHeader.propTypes = {
  headline: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  hasDivider: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
  sidebarItems: PropTypes.array
}

PageHeader.defaultProps = {
  headline: 'Please add the headline.',
  text: undefined,
  hasDivider: true,
  buttonLabel: '',
  buttonLink: '',
  sidebarItems: []
}

export default PageHeader

export const query = graphql`
  fragment PageHeader on ContentfulPageHeader {
    id
    buttonLabel
    buttonLink
    headline {
      headline
    }
    text {
      text
    }
    hasDivider
    backgroundImage {
      file {
        url
      }
    }
    sidebarItems {
      __typename
      ... on ContentfulQuote {
        id
        text {
          text
        }
      }
      ...on ContentfulPost {
        id
        title
        slug
      }
    }
  }
`
