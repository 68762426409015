import * as styles from './index.module.scss'

import React, { useState } from "react"
import Carousel, { Modal, ModalGateway } from 'react-images'

import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Container from '../container'
import DownloadsListingItem from '../downloads/item'
import TextRenderer from '../text/renderer'

const FooterCaption = (props) => {
  const { currentView } = props
  const { caption } = currentView

  return (
    <span>
      {caption}
    </span>
  )
}

const FooterCount = (props) => {
  const { currentView } = props

  return (
    <div className={styles.imgDownload}>
      <DownloadsListingItem title={`Download Bild`} url={currentView.download} />
    </div>
  )
}

const Gallery = ({ title, teaser, preview, download, photos }) => {
  const [showModal, setShowModal] = useState(false)

  const images = photos.map(photo => {
    return {
      caption: photo.description,
      source: photo.file.url + '?w=1600',
      download: photo.file.url
    }
  })

  return (
    <section>
      <Container>
        <div className={styles.row}>
          <div className={styles.image}>
            {(preview &&
              <GatsbyImage image={preview?.gatsbyImageData} />
            )}
          </div>
          <div className={styles.body}>
            {(title !== '' &&
              <div className={styles.title}>{title}</div>
            )}
            {(teaser !== '' &&
              <div className={styles.teaser}>
                <TextRenderer text={teaser} />
              </div>
            )}
            {(photos.length > 0 &&
              <div className={styles.download}>
                <div className={styles.anchor} onClick={() => setShowModal(true)}>
                  <span>Bilder einzeln ansehen und herunterladen</span>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                    <path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" fill="currentColor" />
                  </svg>
                </div>
              </div>
            )}
            {(download &&
              <div className={styles.download}>
                <DownloadsListingItem title={`Download ${title}`} url={download} />
              </div>
            )}
          </div>
        </div>
      </Container>
      <div>
        <ModalGateway>
          {showModal ? (
            <Modal
              allowFullscreen={false}
              onClose={() => setShowModal(false)}
              styles={{
                blanket: base => ({
                  ...base,
                  backgroundColor: 'rgba(255,255,255,0.85)',
                }),
                dialog: base => ({
                  ...base,
                  maxWidth: 640,
                  width: '90vw'
                }),
              }}
            >
              <Carousel
                components={{ FooterCaption, FooterCount }}
                frameProps={{ autoSize: true }}
                hideControlsWhenIdle={false}
                views={images}
                styles={{
                  footer: base => ({
                    ...base,
                    background: 'none !important',
                    color: '#666',
                    padding: 0,
                    paddingTop: 20,
                    position: 'static',

                    '& a': {
                      color: 'black',
                    },
                  }),
                  navigationNext: base => ({
                    ...base,
                    color: '#CC0000',
                    backgroundColor: 'transparent',
                    position: 'fixed',
                    right: '10vw'
                  }),
                  navigationPrev: base => ({
                    ...base,
                    color: '#CC0000',
                    backgroundColor: 'transparent',
                    position: 'fixed',
                    left: '10vw'
                  }),
                  header: base => ({
                    ...base,
                    background: 'none !important',
                    padding: 0,
                    paddingBottom: 10,
                    position: 'static',
                  }),
                  headerClose: base => ({
                    ...base,
                    color: '#666',

                    ':hover': { color: '#DE350B' },
                  }),
                  view: base => ({
                    ...base,
                    minHeight: '100px'
                  })
                }}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </div>
    </section>
  )
}

Gallery.defaultProps = {
  title: 'Please remember to add a title',
  teaser: '',
  photos: []
}

export default Gallery

export const query = graphql`
  fragment Gallery on ContentfulGallery {
    id
    title
    key
    body {
      body
    }
    photos {
      id
      title
      description
      file {
        url
      }
    }
    download {
      title
      description
      id
      contentful_id
      file {
        url
        details {
          image {
            width
            height
          }
        }
      }
    }
    cover {
      gatsbyImageData(
        aspectRatio: 1.4953271028
        cropFocus: CENTER
        resizingBehavior: THUMB
        placeholder: DOMINANT_COLOR
        quality: 75
        width: 320
      )
    }
  }
`

// TODO: image, photos, cover
