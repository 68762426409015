import * as styles from './index.module.scss'

import CallToAction from '../calltoaction'
import Container from '../container'
import Divider from '../divider'
import PropTypes from 'prop-types'
import React from "react"
import TextRenderer from '../text/renderer'
import { graphql } from 'gatsby'

const Quote = ({ text, author, ctaLabel, ctaLink }) => {
  return (
    <section className={styles.quote}>
      <Container>
        <Divider/>
        <div className={styles.text}>
          <TextRenderer text={`${text}`}/>
        </div>
        <div className={styles.author}>{author}</div>
        <div className={styles.cta}>
          <CallToAction ctaLabel={ctaLabel} ctaLink={ctaLink}/>
        </div>
      </Container>
    </section>
  )
}

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string
}

Quote.defaultProps = {
  text: 'Please add the quote text.',
  author: "Please add the author",
  ctaLabel: '',
  ctaLink: ''
}

export default Quote

export const query = graphql`
  fragment Quote on ContentfulQuote {
    id
    text {
      text
    }
    author
    ctaLabel
    ctaLink
  }
`
