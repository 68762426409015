import * as styles from './index.module.scss'

import CategoryArtist from './artist'
import Container from '../container'
import PropTypes from 'prop-types'
import React from "react"
import { graphql } from 'gatsby'

const Category = ({ title, nominees, winner }) => {
  const items = nominees.map(nominee => (
    <CategoryArtist key={nominee.id} lastName={nominee.lastName} firstName={nominee.firstName} biographie={(nominee.biographie && nominee.biographie.biographie) || undefined} statement={(nominee.statement && nominee.statement.statement) || undefined} visual={nominee.visual} members={nominee.members}/>
  ))

  return (
    <section className={styles.wrapper}>
      <Container>
        <div className={styles.category}>Nominierte in der Kategorie</div>
        <div className={styles.title}>{title}</div>
        <div className={styles.nominees}>
          {items}
        </div>
      </Container>
    </section>
  )
}

Category.propTypes = {
  title: PropTypes.string.isRequired,
  nominees: PropTypes.array,
  winner: PropTypes.object
}

Category.defaultProps = {
  title: 'Please add the title.',
  nominees: [],
  winner: { id: "no-winner-id-is-here" }
}

export default Category

export const query = graphql`
  fragment Category on ContentfulCategory {
    id
    title
    nominees {
      id
      lastName
      firstName
      members {
        id
        lastName
        firstName
      }
      biographie {
        biographie
      }
      statement {
        statement
      }
      visual {
        gatsbyImageData(
          aspectRatio: 1.4953271028
          cropFocus: CENTER
          resizingBehavior: THUMB
          quality: 75
          width: 350
        )
      }
    }
    winner {
      id
      visual {
        gatsbyImageData(
          aspectRatio: 1.4953271028
          cropFocus: CENTER
          resizingBehavior: THUMB
          quality: 75
          width: 350
        )
      }
    }
  }
`
