import * as styles from './player.module.scss'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from "react"
import CallToAction from '../calltoaction'
import Container from '../container'
import Video from './video'

const VideoPlayer = ({ title, youTubeUrl = 'you?v=2', previewImage, hasBackground, ctaLabel, ctaLink }) => (
  <section className={styles.wrapper} data-has-background={hasBackground}>
    <Container>
      <div className={styles.row}>
        {title && <div className={styles.title}>{title}</div>}
        <Video title={title} youTubeUrl={youTubeUrl} previewImage={previewImage} />
        {((ctaLabel !== '' && ctaLink !== '') &&
          <div className={styles.cta}>
            <CallToAction ctaLabel={ctaLabel} ctaLink={ctaLink} />
          </div>
        )}
      </div>
    </Container>
  </section>
)

VideoPlayer.propTypes = {
  title: PropTypes.string,
  youTubeUrl: PropTypes.string,
  hasBackground: PropTypes.bool,
  ctaLabel: PropTypes.string,
  ctaLink: PropTypes.string
}

VideoPlayer.defaultProps = {
  hasBackground: false,
  title: undefined,
  ctaLabel: '',
  ctaLink: ''
}

export default VideoPlayer

export const query = graphql`
  fragment YouTubePlayer on ContentfulYouTubePlayer {
    id
    title
    youTubeUrl
    ctaLabel
    ctaLink
    previewImage {
      gatsbyImageData(
        aspectRatio: 1.7777777778
        cropFocus: CENTER
        resizingBehavior: THUMB
        placeholder: DOMINANT_COLOR
        quality: 75
        width: 2060
      )
    }
    hasBackground
  }
`
