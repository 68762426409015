import * as styles from './grid.module.scss'

import CallToAction from '../calltoaction'
import Container from '../container'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from "react"
import { graphql } from 'gatsby'
import { pathTo } from '../../routes'

const JudgesGrid = ({ judges }) => {
  const items = judges.map(judge => (
    <div key={judge.id} className={styles.item}>
      <div className={styles.judge}>
        <div className={styles.visual}>
          <Link to={pathTo(judge)}>
            <GatsbyImage image={judge?.visual?.gatsbyImageData} alt={`${judge.firstname} ${judge.lastname}`}/>
          </Link>
        </div>
        <div className={styles.info}>
          <div className={styles.name}>
            <Link to={pathTo(judge)}>
              {judge.firstname}<br/>{judge.lastname}
            </Link>
          </div>
          <div className={styles.category}>
            {judge.category.title}
          </div>
          <div className={styles.cta}>
            <CallToAction ctaLabel='Mehr erfahren' ctaLink={pathTo(judge)}/>
          </div>
        </div>
      </div>
    </div>
  ))

  return (
    <section>
      <Container>
        <div className={styles.judges}>
          {items}
        </div>
      </Container>
    </section>
  )
}

JudgesGrid.propTypes = {
  judges: PropTypes.array.isRequired
}

JudgesGrid.defaultProps = {
  judges: []
}

export default JudgesGrid

export const query = graphql`
  fragment JudgesGrid on ContentfulJudgesGrid {
    id
    judges {
      __typename
      id
      firstname
      lastname
      slug
      category {
        title
      }
      visual {
        id
        gatsbyImageData(
          aspectRatio: 1
          cropFocus: CENTER
          resizingBehavior: THUMB
          placeholder: DOMINANT_COLOR
          quality: 75
          width: 364
        )
      }
    }
  }
`
