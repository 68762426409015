import * as styles from './artist.module.scss';

import React, { useEffect, useState } from "react";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import TextRenderer from '../text/renderer';
import Toggle from '../toggle';

export const artistTitle = (artist) => {
  let completeName = artist.lastName;

  if (artist.firstName !== null && artist.firstName !== '') {
    completeName = `${artist.firstName} ${completeName}`;
  }
  return completeName;
}

const CategoryArtist = ({ lastName, firstName, biographie, statement, visual, members }) => {
  const [showBiography, setShowBiography] = useState(false);
  let title = lastName;

  if (firstName !== null && firstName !== '') {
    title = `${firstName} ${title}`;
  }

  const memberNames = members ? members.map(member => artistTitle(member)).join(', ') : '';

  useEffect(() => {
    if (showBiography) {
      disableBodyScroll(window);
    } else {
      enableBodyScroll(window);
    }
  })

  return (
    <div className={styles.nominee} data-show-biography={showBiography}>
      <div className={styles.image} onClick={() => setShowBiography(!showBiography)}>
        {( visual &&
          <GatsbyImage image={visual?.gatsbyImageData} alt={title}/>
        )}
        {( !visual &&
          <div className={styles.placeholder}/>
        )}
      </div>
      <div className={styles.info}>
        {( title && <div className={styles.title} onClick={() => setShowBiography(!showBiography)}>{title}</div>)}
        <div className={styles.toggle} data-active={showBiography}>
          <Toggle onClick={() => setShowBiography(!showBiography)} active={showBiography}/>
        </div>
      </div>
      <div className={styles.modal}>
        <div className={styles.modalClose}>
          <div className={styles.close} onClick={() => setShowBiography(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
              <g id="Group_5" transform="translate(5725 -2430.5)">
                <g className={styles.stroke} id="Rectangle" transform="translate(-5725 2430.5)" fill="none" stroke="#212529" strokeMiterlimit="10" strokeWidth="2">
                  <rect width="40" height="40" stroke="none"/>
                  <rect x="1" y="1" width="38" height="38" fill="none"/>
                </g>
                <g id="Group_4" data-name="Group 4">
                  <path className={styles.cross} id="Path_2" data-name="Path 2" d="M0,.333H20" transform="translate(-5712.307 2457.335) rotate(-45)" fill="none" stroke="#212529" strokeMiterlimit="10" strokeWidth="2"/>
                  <path className={styles.cross} id="Path_2_Copy" data-name="Path 2 Copy" d="M0,.333H20" transform="translate(-5711.835 2443.193) rotate(45)" fill="none" stroke="#212529" strokeMiterlimit="10" strokeWidth="2"/>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div className={styles.modalContent}>
          {( title &&
            <div className={styles.modalName}>{title}</div>
          )}
          {( members &&
            <div className={styles.modalMembers}>Mitglieder: {memberNames}</div>
          )}
          {( statement &&
            <div className={styles.modalStatement}>
              <TextRenderer text={statement}/>
            </div>
          )}
          {( biographie &&
            <div className={styles.modalBiographie}>
              <TextRenderer text={biographie}/>
            </div>
          )}
        </div>
        <div className={styles.backdrop} onClick={() => setShowBiography(false)}/>
      </div>
    </div>
  )
};

CategoryArtist.propTypes = {
  lastName: PropTypes.string.isRequired,
  biographie: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  visual: PropTypes.object
}

CategoryArtist.defaultProps = {
  lastName: 'Please add a name to the artist',
  biographie: 'Keine Biografie vorhanden.'
}

export default CategoryArtist;
